import { createSlice } from '@reduxjs/toolkit';
import { appLanguages } from '../constants/appConstants';

const initialState = {
  appLanguage: appLanguages.Bengali,
};

const generalSlice = createSlice({
  name: 'generalSlice',
  initialState,
  reducers: {
    resetGeneral(state) {
      Object.assign(state, initialState);
    },
    changeLanguage(state, action) {
      state.appLanguage = action.payload;
    },
  },
  //   extraReducers: (builder) => {},
});

export const { resetGeneral, changeLanguage } = generalSlice.actions;

//selector
export const generalReducer = (state) => state.general;

export default generalSlice.reducer;
